export default {
  "AppName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブラウザフォン"])},
  "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー名"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
  "Memorize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記憶する"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])},
  "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
  "Dial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発信"])},
  "Hangup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切断"])},
  "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
  "Control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制御"])},
  "Mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミュート"])},
  "Unmute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミュート解除"])},
  "InMute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイクOFF中"])},
  "Pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留"])},
  "Pausing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中"])},
  "ReloadQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キュー更新"])},
  "Reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
  "Keypad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キーパッド"])},
  "Output Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出力デバイス"])},
  "Speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音声"])},
  "Ringtone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知音"])},
  "Input Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力デバイス"])},
  "Accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受話"])},
  "Reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否"])},
  "Opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話相手"])},
  "Calllog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話履歴"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日時"])},
  "Direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方向"])},
  "From": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "Recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["録音"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状態"])},
  "Duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話時間"])},
  "Busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話中"])},
  "NO_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当がありません"])},
  "MSG_PUSHED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "が押されました"])},
  "MSG_FAIL_TO_FETCH_TOKEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークンが取得できません"])},
  "MSG_ATTEMPTING_TO_CALL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 宛に発信中"])},
  "MSG_CALL_IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話中"])},
  "MSG_CALL_DISCONNECTED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "との通話を切断しました"])},
  "MSG_CALL_ENDED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "との通話が終了しました"])},
  "MSG_CALL_CANCELED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "との通話がキャンセルされました"])},
  "MSG_INCOMING_CALL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "から着信です"])},
  "MSG_ACCEPTED_INCOMING_CALL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "と通話を開始しました"])},
  "MSG_REJECTED_INCOMING_CALL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "からの着信を拒否しました"])},
  "MSG_HANGING_UP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "との通話を切断します"])},
  "MSG_ENQUEUE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "をキューに入れて通話を切断します"])},
  "MSG_QUEUE_EMPTY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["このキュー(", _interpolate(_list(0)), ")で待機している通話はありません"])},
  "MSG_CONFIRM_DISCONNECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話が切断されますが、よろしいですか？"])},
  "MSG_GROUP_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ未割当もしくは複数割当です。サポートに問い合わせてください"])},
  "DEVICE_MSG_REGISTERING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録中"])},
  "DEVICE_MSG_REGISTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録完了。着信ができます"])},
  "DEVICE_MSG_UNREGISTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録解除。着信はできません"])},
  "MSG_MAKE_CALL_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発信エラー"])},
  "registering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録中"])},
  "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["着信可能"])},
  "unregistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["着信不可"])},
  "destroyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["着信不可"])}
}