import { Device } from '@twilio/voice-sdk'
import { Auth, API, Hub } from 'aws-amplify'
import { phoneNumber } from '@/utils'

const LAMBDA_NAME = 'TwilioVoice'
const TWILIO_EDGE = 'tokyo'

export class PhoneClient {
  user = null
  tokenData = null
  device = null
  retryCount = 0
  leaving = false
  handlers = {
    log: (...data) => { console.log(data) },
    $t: null,
    registered: null,
    incomingCall: null,
    callAccepted: null,
    callDone: null,
    volumeIndicators: null,
    updateDeviceState: null,
    updateIncomingCalls: null,
    updateQueues: null,
    updateOutputDevices: null,
    updateSpeakerDevices: null,
    updateRingtoneDevices: null,
    updateInputDevice: null,
    updateInputDevices: null
  }

  incomingCalls = {}

  constructor (user, handlers) {
    this.user = user
    this.handlers = handlers
    const that = this
    const leave = function (data) {
      console.log('auth event', data)
      if (data.payload.event === 'signOut') {
        that.leaving = true
        if (that.device && (that.device.state === 'registered')) {
          that.device.unregister()
        }
        Hub.remove('auth', leave)
      }
    }
    Hub.listen('auth', leave)
  }

  async init () {
    try {
      this.tokenData = await this.getTokenData(this.user)
      const device = new Device(this.tokenData.token, {
        // logLevel: 1,
        codecPreferences: ['opus', 'pcmu'],
        tokenRefreshMs: 30000,
        edge: TWILIO_EDGE
      })
      this.addDeviceListeners(device)
      device.register()
      this.device = device
    } catch (err) {
      this.log(err)
      throw err
    }
    return this
  }

  async getHeader () {
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    }
    return myInit
  }

  async getTokenData (user) {
    const identity = user.username
    const res = await API.get(LAMBDA_NAME, `/token?identity=${encodeURIComponent(identity)}`, await this.getHeader())
    console.log(res)
    return res
  }

  log (message) {
    this.handlers.log(message)
  }

  addDeviceListeners (device) {
    device.on('registering', _device => {
      const msg = this.handlers.$t('DEVICE_MSG_REGISTERING')
      this.log(msg)
      this.handlers.updateDeviceState(device.state)
    })
    device.on('registered', _device => {
      const msg = this.handlers.$t('DEVICE_MSG_REGISTERED')
      this.log(msg)
      this.handlers.updateDeviceState(device.state)
      this.retryCount = 0
      this.handlers.registered(_device)
    })
    device.on('unregistered', _device => {
      const msg = this.handlers.$t('DEVICE_MSG_UNREGISTERED')
      this.log(msg)
      if (this.leaving) return
      this.handlers.updateDeviceState(device.state)
      this.retryCount += 1
      if (this.retryCount < 3) {
        device.register(_device)
      }
    })
    device.on('error', error => {
      this.log('Twilio.Device Error: ' + error.message)
      this.handlers.updateDeviceState(device.state)
    })
    device.on('tokenWillExpire', async () => {
      const tokenData = await this.getTokenData(this.user)
      device.updateToken(tokenData.token)
    })
    device.on('incoming', this.handleIncomingCall.bind(this))
    device.audio.on('deviceChange', lostActiveDevices => this.updateAllAudioDevices(lostActiveDevices))
  }

  handleIncomingCall (call) {
    call.formatedFrom = phoneNumber(call.parameters.From)
    call.rawFrom = call.parameters.From
    call.sid = call.parameters.CallSid
    call.createdAt = new Date()
    this.log(this.handlers.$t('MSG_INCOMING_CALL', [call.formatedFrom]))
    call.on('accept', () => this.handleIncomingCallAccepted(call))
    call.on('cancel', () => this.handleIncomingCallCanceled(call))
    call.on('disconnect', call => this.handleIncomingCallDisconnected(call))
    call.on('reject', () => this.handleIncomingCallRejected(call))
    call.on('volume', (inputVolume, outputVolume) => this.handlers.volumeIndicators(inputVolume, outputVolume))
    call.on('accepted', this.handlers.callAccepted)
    call.on('done', this.handlers.callDone)
    if (this.hasOpenCall()) call.ignore()
    this.incomingCalls[call.sid] = call
    this.updateIncomingCalls()
  }

  getCall (sid) {
    return this.incomingCalls[sid]
  }

  hasOpenCall () {
    return Object.values(this.incomingCalls).some(v => v.status() === 'open')
  }

  updateIncomingCalls () {
    const calls = Object.values(this.incomingCalls).sort((a, b) => a.createdAt - b.createdAt)
    this.handlers.updateIncomingCalls(calls.map(v => v.sid))
  }

  removeActiveIncomingCall (call) {
    call.emit('done', call)
    delete this.incomingCalls[call.parameters.CallSid]
    this.updateIncomingCalls()
  }

  handleIncomingCallAccepted (call) {
    this.log(this.handlers.$t('MSG_ACCEPTED_INCOMING_CALL', [call.formatedFrom]))
    const _incomingCalls = []
    for (const key in this.incomingCalls) {
      const c = this.incomingCalls[key]
      console.log(c.parameters.From, c.status())
      if (c.status() === 'open') {
        this.createQueue(call.rawFrom).then(v => {
          console.log(v)
        })
      } else if (c.status() === 'pending') {
        _incomingCalls.push(this.pause(c))
        // c.reject()
      }
    }
    call.emit('accepted', call)
    if (_incomingCalls.length > 0) {
      Promise.all(_incomingCalls).then(v => {
        console.log(v)
        this.getQueues()
      })
    }
    this.updateIncomingCalls()
  }

  handleIncomingCallCanceled (call) {
    this.log(this.handlers.$t('MSG_CALL_CANCELED', [call.formatedFrom]))
    this.removeActiveIncomingCall(call)
  }

  handleIncomingCallDisconnected (call) {
    this.log(this.handlers.$t('MSG_CALL_ENDED', [call.formatedFrom]))
    this.removeActiveIncomingCall(call)
  }

  handleIncomingCallRejected (call) {
    this.log(this.handlers.$t('MSG_REJECTED_INCOMING_CALL', [call.formatedFrom]))
    this.removeActiveIncomingCall(call)
  }

  async makeOutgoingCall (params) {
    try {
      const call = await this.device.connect({ params })
      call.formatedTo = phoneNumber(params.To || params.Queue)
      call.rawTo = params.To || params.Queue.replace(/^(?!client:)/, 'client:')
      call.sid = call.parameters.CallSid
      call.createdAt = new Date()
      call.on('accept', this.handleOutgoingCallAccept.bind(this))
      call.on('disconnect', this.handleOutgoingCallDisconnected.bind(this))
      call.on('cancel', this.handleOutgoingCallCanceled.bind(this))
      call.on('volume', this.handlers.volumeIndicators)
      call.on('accepted', this.handlers.callAccepted)
      call.on('done', this.handlers.callDone)
      return call
    } catch (err) {
      console.log(err)
      this.log(this.handlers.$t('MSG_MAKE_CALL_ERROR'))
      return null
    }
  }

  async handleOutgoingCallAccept (call) {
    this.log(this.handlers.$t('MSG_CALL_IN_PROGRESS'))
    if (call.customParameters.get('Queue')) {
      const callSid = await this.getCounterCallSid(call)
      call.customParameters.set('CounterCallSid', callSid)
    }
    this.createQueue(call.rawTo).then(v => {
      console.log(v)
      this.getQueues()
    })
    call.emit('accepted', call)
  }

  handleOutgoingCallDisconnected (call) {
    this.log(this.handlers.$t('MSG_CALL_DISCONNECTED', [call.formatedTo]))
    console.log('handleOutgoingCallDisconnected')
    call.emit('done', call)
  }

  handleOutgoingCallCanceled (call) {
    this.log(this.handlers.$t('MSG_CALL_CANCELED', [call.formatedTo]))
    console.log('handleOutgoingCallCanceled')
    call.emit('done', call)
  }

  disconnect () {
    this.device.disconnectAll()
  }

  async getCounterCallSid (call) {
    const counterCallSid = call.customParameters?.get('CounterCallSid')
    if (counterCallSid) return counterCallSid
    const callSid = call.parameters.CallSid
    const res = await API.get(LAMBDA_NAME, `/counterCall/${callSid}`, await this.getHeader())
    const [counterCall] = res.calls
    return counterCall.sid
  }

  async pause (call) {
    const counterCallSid = await this.getCounterCallSid(call)
    return await API.post(LAMBDA_NAME, `/pause/${counterCallSid}`, await this.getHeader())
  }

  // async unpause (counterCallSid, callSid) {
  //   return await API.post(LAMBDA_NAME, `/unpause/${counterCallSid || callSid}_${callSid}`, await this.getHeader())
  // }
  async getQueues (delaySec = 0) {
    if (delaySec > 0) {
      const me = this
      setTimeout(() => { me.getQueues(0) }, delaySec * 1000)
      return
    }
    const queues = (await this.listQueues()).queues.filter(v => v.currentSize > 0)
    this.handlers.updateQueues(queues)
  }

  async listQueues () {
    return await API.get(LAMBDA_NAME, '/queues', await this.getHeader())
  }

  async createQueue (queueName) {
    return await API.post(LAMBDA_NAME, `/queue/${queueName}`, await this.getHeader())
  }

  async getCallFromQueue (queueName) {
    const queue = (await API.get(LAMBDA_NAME, `/queue/${queueName}`, await this.getHeader()))
    return (queue.result === 'ok') ? queue.call : null
  }

  async deleteQueue (queueName) {
    return await API.delete(LAMBDA_NAME, `/queue/${queueName}`, await this.getHeader())
  }

  async getCalllog () {
    return await API.get(LAMBDA_NAME, '/calllog', await this.getHeader())
  }

  updateAllAudioDevices () {
    if (!this.device) return
    return {
      allInputDevices: new Set(this.device.audio.availableInputDevices.values()),
      allOutputDevices: new Set(this.device.audio.availableOutputDevices.values()),
      currentSpeakerDevices: this.device.audio.speakerDevices.get(),
      currentRingtoneDevices: this.device.audio.ringtoneDevices.get(),
      currentInputDevices: this.device.audio.inputDevice
    }
  }

  setSpeakerDevices (devices) {
    if (!this.device) return
    this.device.audio.speakerDevices.set(devices)
  }

  setRingtoneDevices (devices) {
    if (!this.device) return
    this.device.audio.ringtoneDevices.set(devices)
  }

  async setInputDevice (device) {
    if (!this.device) return
    return await this.device.audio.setInputDevice(device)
  }
}
