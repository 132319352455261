<template>
  <section class="notification">
    <o-field :label="$t('Output Device')">
      <o-field :label="$t('Speaker')">
        <o-select v-model="selectedSpeakerDevices" @change="$emit('changeSpeakerDevices', selectedSpeakerDevices)" multiple>
        <option v-for="option in audio.allOutputDevices" :key="option.deviceId" :value="option.deviceId" :selected="selectedSpeakerDevices.includes(option.deviceId)">
            {{ option.label }}
        </option>
        </o-select>
      </o-field>
      <o-field :label="$t('Ringtone')">
        <o-select v-model="selectedRingtoneDevices" @change="$emit('changeRingtoneDevices', selectedRingtoneDevices)" multiple>
        <option v-for="option in audio.allOutputDevices" :key="option.deviceId" :value="option.deviceId" :selected="selectedRingtoneDevices.includes(option.deviceId)">
            {{ option.label }}
        </option>
        </o-select>
      </o-field>
    </o-field>
    <o-field :label="$t('Input Device')">
      <o-select v-model="selectedInputDevice" @change="$emit('changeInputDevice', selectedInputDevice)">
        <option v-for="option in audio.allInputDevices" :key="option.deviceId" :value="option.deviceId" :selected="selectedInputDevice == option.deviceId">
        {{ option.label }}
        </option>
      </o-select>
    </o-field>
    <o-field :label="$t('Reload')">
      <o-button icon-pack="fa fa-fw" icon-left="fa-sync" @click="$emit('reload')" />
    </o-field>
  </section>
</template>

<script>
export default {
  name: 'Settings',
  props: {
    audio: Object
  },
  data () {
    return {
      selectedSpeakerDevices: ['default'],
      selectedRingtoneDevices: ['default'],
      selectedInputDevice: 'default'
    }
  },
  updated () {
    this.selectedSpeakerDevices = Array.from(this.audio.currentSpeakerDevices).map(v => v.deviceId)
    this.selectedRingtoneDevices = Array.from(this.audio.currentRingtoneDevices).map(v => v.deviceId)
    this.selectedInputDevice = this.audio.currentInputDevices?.deviceId
  }
}
</script>
