<template>
    <div style="position: relative">
      <o-table
        ref="calllog"
        :data="logs"
        :hoverable="true"
        :narrowed="false"
        :row-class="onRowClass"
        :mobile-cards="true">
          <o-table-column field="startTime" :label="$t('Date')" v-slot="props">
            <o-tooltip :label="dateTooltop(props.row)" multiline position="bottom">
                {{ formatCallTime(props.row.startTime) }}
            </o-tooltip>
          </o-table-column>
          <!-- <o-table-column field="direction" :label="$t('Direction')" centered v-slot="props">
              {{ {'outbound-dial': 'out', 'inbound': 'in'}[props.row.direction] }}
          </o-table-column> -->
          <o-table-column field="from" label="From" v-slot="props">
              {{ formatNumber(props.row.from) }}
          </o-table-column>
          <o-table-column field="to" label="To" v-slot="props">
              {{ formatNumber(props.row.to) }}
          </o-table-column>
          <o-table-column field="status" :label="$t('Status')" centered v-slot="props">
              {{ props.row.status }}
          </o-table-column>
          <!-- <o-table-column field="recordingUri" :label="$t('Recording')" centered v-slot="props">
              <span v-for="uri in props.row.recordingUri" :key="uri">
                  <a :href="uri" target="_blank">
                  <o-icon icon="play-circle" size="is-small"></o-icon>
                  </a>
              </span>
          </o-table-column> -->
          <!-- <o-table-column field="duration" :label="$t('Duration')" numeric v-slot="props">
              {{ props.row.duration }} secs
          </o-table-column> -->
      </o-table>
      <o-loading :full-page="false" v-model:active="isLoading" :can-cancel="false">
        <o-icon pack="fas" icon="sync-alt" size="large" spin> </o-icon>
      </o-loading>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import { phoneNumber } from '@/utils'

moment.tz.setDefault('Asia/Tokyo')
moment.locale('ja')

export default {
  name: 'Calllog',
  props: {
    data: Array,
    loading: Boolean
  },
  computed: {
    logs () { return this.data.length ? this.data : [] },
    isLoading () { return this.loading }
  },
  methods: {
    formatNumber (value) {
      return phoneNumber(value).replace(/^client:/, '')
    },
    onRowClass (row) {
      return row.status
    },
    dateTooltop (row) {
      return moment(row.startTime).format('YYYY/MM/DD(ddd) HH:mm:ss') + ' ' + moment(row.endTime).format('YYYY/MM/DD(ddd) HH:mm:ss')
    },
    formatCallTime (value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>
