export default {
  "AppName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BrowserPhone"])},
  "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "Memorize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memorize"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PhoneNumber"])},
  "Dial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dial"])},
  "Hangup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangup"])},
  "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "Control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control"])},
  "Mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute"])},
  "Unmute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmute"])},
  "InMute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mic is in mute"])},
  "Pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])},
  "Pausing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausing"])},
  "ReloadQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload Queue"])},
  "Reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
  "Keypad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keypad"])},
  "Output Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output Device"])},
  "Speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speaker"])},
  "Ringtone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringtone"])},
  "Input Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Device"])},
  "Accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "Reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
  "Opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent"])},
  "Calllog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calllog"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "Direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
  "From": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "Recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recording"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "Duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
  "Busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busy"])},
  "NO_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No result found"])},
  "MSG_PUSHED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pushed ", _interpolate(_list(0))])},
  "MSG_FAIL_TO_FETCH_TOKEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not fetch token"])},
  "MSG_ATTEMPTING_TO_CALL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Attempting to call ", _interpolate(_list(0))])},
  "MSG_CALL_IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call in progress..."])},
  "MSG_CALL_DISCONNECTED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Call with ", _interpolate(_list(0)), " disconnected."])},
  "MSG_CALL_ENDED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Incoming call from ", _interpolate(_list(0)), " ended."])},
  "MSG_CALL_CANCELED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Incoming call from ", _interpolate(_list(0)), " canceled."])},
  "MSG_INCOMING_CALL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Incoming call from ", _interpolate(_list(0))])},
  "MSG_ACCEPTED_INCOMING_CALL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Accepted incoming call from ", _interpolate(_list(0))])},
  "MSG_REJECTED_INCOMING_CALL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Rejected incoming call from ", _interpolate(_list(0))])},
  "MSG_HANGING_UP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hanging up call wiht ", _interpolate(_list(0))])},
  "MSG_ENQUEUE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Enqueue a call with ", _interpolate(_list(0)), " and disconnected"])},
  "MSG_QUEUE_EMPTY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["This queue (", _interpolate(_list(0)), ") is empty."])},
  "MSG_CONFIRM_DISCONNECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect call, ok?"])},
  "MSG_GROUP_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no group or muliple groups. Please contact to a support"])},
  "DEVICE_MSG_REGISTERING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registering"])},
  "DEVICE_MSG_REGISTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready"])},
  "DEVICE_MSG_UNREGISTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
  "MSG_MAKE_CALL_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to make call"])},
  "registering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registering"])},
  "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["online"])},
  "unregistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offline"])},
  "destroyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offline"])}
}