<template>
<footer class="footer">
  <div class="content has-text-centered">
    <p>&copy; 2022 <a href="https://www.123robo.com/company">SYD</a>&nbsp;<span>Ver. {{ appVersion }}</span></p>
  </div>
</footer>
</template>

<script>
const appVersion = require('../../package.json').version

export default {
  name: 'GlobalFooter',
  data () {
    return {
      appVersion: appVersion
    }
  }
}
</script>
