export function phoneNumber (number) {
  if (!number) {
    return ''
  }
  const INVALID_CALL_NUMBERS = ['+266696687', '+86282452253', '+8656696', '+2562533', '+7378742833']
  if (INVALID_CALL_NUMBERS.indexOf(number) > -1) {
    return '非通知'
  }
  return number.replace(/^[+]81(.+)$/, '0$1').replace(/^client:/, '')
}
