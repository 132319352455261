import axios from 'axios'
import VueAxios from 'vue-axios'
import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'
import Amplify, { I18n } from 'aws-amplify'
import awsExports from './aws-exports'

import '@oruga-ui/theme-bulma/dist/bulma.css'

import { vocabularies } from '@/locales/ja/vocabularies'

I18n.putVocabularies(vocabularies)
I18n.setLanguage('ja')

Amplify.configure(awsExports)

const store = createStore({
  state () {
    return {
      myNumber: '',
      deviceState: ''
    }
  },
  mutations: {
    setMyNumber (state, value) {
      state.myNumber = value
    },
    setDeviceState (state, value) {
      state.deviceState = value
    }
  }
})

const app = createApp(App)
app.use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(i18n)
  .use(Oruga, bulmaConfig)
  .mount('#app')
