<template>
  <section>
    <o-field grouped groupedClass="groupedCenterClass">
      <o-button rounded :size="uiSize" @click="$emit('dtmf', '1')" :disabled="disabled">1</o-button>
      <o-button rounded :size="uiSize" @click="$emit('dtmf', '2')" :disabled="disabled">2</o-button>
      <o-button rounded :size="uiSize" @click="$emit('dtmf', '3')" :disabled="disabled">3</o-button>
    </o-field>
    <o-field grouped groupedClass="groupedCenterClass">
      <o-button rounded :size="uiSize" @click="$emit('dtmf', '4')" :disabled="disabled">4</o-button>
      <o-button rounded :size="uiSize" @click="$emit('dtmf', '5')" :disabled="disabled">5</o-button>
      <o-button rounded :size="uiSize" @click="$emit('dtmf', '6')" :disabled="disabled">6</o-button>
    </o-field>
    <o-field grouped groupedClass="groupedCenterClass">
      <o-button rounded :size="uiSize" @click="$emit('dtmf', '7')" :disabled="disabled">7</o-button>
      <o-button rounded :size="uiSize" @click="$emit('dtmf', '8')" :disabled="disabled">8</o-button>
      <o-button rounded :size="uiSize" @click="$emit('dtmf', '9')" :disabled="disabled">9</o-button>
    </o-field>
    <o-field grouped groupedClass="groupedCenterClass">
      <o-button rounded :size="uiSize" @click="$emit('dtmf', '*')" :disabled="disabled">*</o-button>
      <o-button rounded :size="uiSize" @click="$emit('dtmf', '0')" :disabled="disabled">0</o-button>
      <o-button rounded :size="uiSize" @click="$emit('dtmf', '#')" :disabled="disabled">#</o-button>
    </o-field>
  </section>
</template>

<script>
export default {
  name: 'DTMFTone',
  props: {
    disabled: Boolean,
    uiSize: String
  }
}
</script>
