<template>
    <nav :class="'navbar ' + navClass" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <a class="navbar-item" href="https://www.123robo.com/">
                123ROBO
            </a>
            <p class="navbar-item mobile">
                {{ $t('AppName') }}
            </p>
            <p class="navbar-item">
              <o-tooltip :label="$t('PhoneNumber')" position="bottom">
                <o-icon pack="fa fa-fw" icon="fa-building" />{{ selfNumber }}
              </o-tooltip>
            </p>
            <p class="navbar-item">{{ $t(deviceState) }}</p>
            <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navMenu" v-bind:class="{ 'is-active': menuActive }" @click="toggleMenu">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div class="navbar-menu" id="navMenu" v-bind:class="{ 'is-active': menuActive }">
            <div class="navbar-end">
                <div class="navbar-item">
                  <a href="https://www.123robo.com/ivr/browser-phone/operating-manual" target="_blank">
                  <span class="icon is-medium"><i class="far btn-circle fa-question-circle"></i></span>
                  </a>
                </div>
                <div class="navbar-item">
                    {{username}}@{{group}}
                </div>
                <div class="navbar-item">
                    <div class="buttons">
                        <a class="button is-warning" @click="logout">{{ $t('Logout') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapState } from 'vuex'
import { phoneNumber } from '@/utils'

export default {
  name: 'GlobalMenu',
  props: ['user', 'signOut'],
  data () {
    return {
      menuActive: false
    }
  },
  computed: {
    ...mapState(['myNumber', 'deviceState']),
    selfNumber () { return phoneNumber(this.myNumber) },
    username () { return this.user.username },
    group () {
      const [grp] = this.user.signInUserSession.idToken.payload['cognito:groups'] || ['NONE']
      return grp
    },
    navClass () {
      if (this.deviceState === 'registering') {
        return 'is-warning'
      } else if (this.deviceState === 'registered') {
        return 'is-primary'
      } else {
        return 'is-danger'
      }
    }
  },
  methods: {
    async logout () {
      this.signOut()
    },
    toggleMenu () {
      this.menuActive = !this.menuActive
    }
  }
}
</script>

<style scoped>
@media(max-width: 600px) {
  .mobile {
    display: none !important;
  }
}
</style>
