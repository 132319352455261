<template>
  <div id="dialer">
    <section>
      <div :class="opponentNumberClass">
        <span class="counterNumber" v-html="opponentNumber"></span>
        <span class="banner on-phone" v-if="onPhone">{{ $t('Busy') }}</span>
      </div>
      <div class="columns">
        <div id="dial-form" class="column">
          <o-field>
            <o-autocomplete
                v-model="currentNumber"
                :data="filteredHistory"
                placeholder="09011112222"
                @select="option => currentNumber = option"
                :size="uiSize"
                icon-right="backspace"
                icon-right-clickable
                @icon-right-click="currentNumber = currentNumber.replace(/.$/, '')"
            >
              <template v-slot:empty>{{ $t('NO_RESULT' )}}</template>
            </o-autocomplete>
          </o-field>
          <o-field grouped groupedClass="groupedCenterClass">
            <o-field>
              <o-button variant="danger" icon-pack="fa fa-fw" icon-left="btn-circle fa-phone-slash" :size="uiSize"
                @click="callHangup" :disabled="!inSession" v-if="inSession">
                {{ $t('Hangup') }}
              </o-button>
              <o-button variant="success" icon-pack="fa fa-fw" icon-left="btn-circle fa-phone" :size="uiSize"
                @click="startOutgoingCall" :disabled="inSession || !isReady || !currentNumber" v-if="!inSession && isReady">
                {{ $t('Dial') }}
              </o-button>
            </o-field>
            <o-field>
              <o-tooltip :label="$t('Settings')">
                <o-button variant="primary" outlined icon-pack="fa fa-fw" icon-left="fa-cog" @click="isSettingsModalActive = true" size="small" />
              </o-tooltip>
            </o-field>
          </o-field>
          <o-collapse :open="false" aria-id="dialKeypad">
            <template v-slot:trigger="props">
              <div class="card-header">
                <o-button class="card-header-title" icon-pack="fa-fw fas" icon-left="fa-calculator" aria-controls="dialKeypad" size="small">{{ $t('Keypad') }}</o-button>
                <o-icon class="card-header-icon" pack="fas" :icon="props.open ? 'caret-up' : 'caret-down'" size="small"> </o-icon>
              </div>
            </template>
            <div class="notification">
              <dtmf-tone @dtmf="pushDTMF" :uiSize="uiSize" />
            </div>
          </o-collapse>
          <o-field>
            <o-field class="notification" grouped>
              <o-field>
                <o-tooltip :label="$t(isMuted ? 'Unmute' : 'Mute')">
                  <o-button rounded icon-pack="fa fa-fw" :icon-left="'btn-circle ' + muteIcon" @click="switchMute" :disabled="!onPhone" :size="uiSize" />
                </o-tooltip>
              </o-field>
              <o-field>
                <o-tooltip :label="$t('Pause')">
                  <o-button icon-pack="fa fa-fw" icon-left="fa-parking" @click="pause" :disabled="!onPhone" :size="uiSize">{{ $t('Pause') }}</o-button>
                </o-tooltip>
                <o-tooltip :label="$t('ReloadQueue')">
                  <o-button icon-pack="fa fa-fw" icon-right="fa-sync" @click="getQueues" :size="uiSize" :disabled="!isReady" />
                </o-tooltip>
                <o-button v-for="option in queues" :key="option.sid" @click="popQueue(option.friendlyName)" :disabled="onPhone">
                  {{ this.formatPhoneNumber(option.friendlyName) }}
                </o-button>
              </o-field>
            </o-field>
          </o-field>
          <div v-if="isMuted">
            <o-notification variant="warning" :message="$t('InMute')" />
          </div>
        </div>
      </div>
      <div class="">
        <o-notification :variant="logType" :message="log" />
        <o-collapse :open="false" aira-id="calllog">
          <template v-slot:trigger="props">
            <div class="card-header">
              <o-button class="card-header-title" @click="getCalllog" icon-pack="fa-fw fas" icon-left="fa-history" aria-controls="calllog" size="small">{{ $t('Calllog') }}</o-button>
              <o-icon class="card-header-icon" pack="fas" :icon="props.open ? 'caret-up' : 'caret-down'" size="small"> </o-icon>
            </div>
          </template>
          <calllog :loading="calllogLoading" :data="calllogs" />
        </o-collapse>
      </div>
    </section>
    <o-modal :active="isSettingsModalActive" @close="isSettingsModalActive=false" contentClass="modalFront">
      <section>
        <settings ref="settings"
          :audio="audio"
          @changeInputDevice="setInputDevice"
          @changeSpeakerDevices="setSpeakerDevices"
          @changeRingtonDevices="setRingtoneDevices"
          @reload="reloadAllAudioDevices"
        />
      </section>
    </o-modal>
    <o-modal :active="isIncomingCallDialogOpen" :can-cancel="false" contentClass="modalFront">
      <section v-for="callSid in incomingPendingCalls" :key="callSid">
        <div class="groupedCenterClass">
          <div class="counterNumber">{{ getCall(callSid).formatedFrom }}</div>
        </div>
        <div>
          <o-field grouped groupedClass="groupedCenterClass">
            <o-button variant="danger" icon-pack="fa fas" icon-left="fa-times" :size="uiSize" @click="incomingCallReject(getCall(callSid))">{{ $t('Reject') }}</o-button>
            <o-button variant="success" icon-pack="fa fas" icon-left="fa-phone" :size="uiSize" @click="incomingCallAccept(getCall(callSid))">{{ $t('Accept') }}</o-button>
          </o-field>
        </div>
      </section>
    </o-modal>
  </div>
</template>

<script>
import DTMFTone from './DTMFTone.vue'
import Settings from './Settings.vue'
import Calllog from './Calllog.vue'
import { PhoneClient } from '@/services.js'
import { mapState, mapMutations } from 'vuex'
import { phoneNumber } from '@/utils'
import { Auth } from 'aws-amplify'

export default {
  name: 'DialerApp',
  components: {
    'dtmf-tone': DTMFTone,
    Settings,
    Calllog
  },
  data () {
    // client must not be reactive, because some unexpected errors occur.
    this.client = null
    return {
      incomingCalls: [],
      activeCall: null,
      audio: {
        inputDevice: 'default',
        inputDevices: new Set(),
        outputDevices: new Set(),
        speakerDevices: ['default'],
        ringtoneDevices: ['default']
      },
      currentNumber: '',
      counterNumber: null,
      queues: [],
      isSettingsModalActive: false,
      countryCode: '81',
      log: '',
      logType: 'info is-light',
      history: [],
      calllogLoading: false,
      calllogs: []
    }
  },
  computed: {
    ...mapState(['myNumber']),
    opponentNumber () {
      return phoneNumber(this.counterNumber) || '&nbsp;'
    },
    opponentNumberClass () {
      return 'notification' + (this.onPhone ? ' is-info' : '')
    },
    filteredHistory () {
      const candidates = this.history.filter(option => {
        return (
          option
            .toString()
            .toLowerCase()
            .replace('-', '')
            .indexOf((this.currentNumber || '').toLowerCase()) >= 0
        )
      })
      return candidates
    },
    uiSize () {
      if (window.matchMedia('(min-width: 700px)').matches) {
        return 'large'
      } else {
        return ''
      }
    },
    isReady () {
      return Boolean(this.myNumber)
    },
    onPhone () {
      return Boolean(this.activeCall)
    },
    inSession () {
      return this.activeCall && this.activeCall.direction
    },
    isIncomingCallDialogOpen () {
      return (this.incomingPendingCalls.length > 0) && !this.hasOpenIncoming
    },
    hasOpenIncoming () {
      return this.incomingCalls.some(v => { return this.getCall(v).status() === 'open' })
    },
    incomingPendingCalls () {
      return this.incomingCalls.filter(v => { return this.getCall(v).status() === 'pending' })
    },
    isMuted () {
      return this.activeCall && this.activeCall.isMuted()
    },
    muteIcon () {
      return this.isMuted ? 'fa-microphone' : 'fa-microphone-slash'
    }
  },
  async mounted () {
    try {
      const user = await Auth.currentUserInfo()
      const groups = (await Auth.currentSession()).getIdToken().payload['cognito:groups'] || []
      if (groups.length !== 1) {
        this.setError(this.$t('MSG_GROUP_ERROR'))
        return
      }
      const handlers = {
        $t: this.$t,
        log: this.setLog,
        updateDeviceState: this.updateDeviceState,
        registered: this.readyToReceiveIncomingCall,
        updateIncomingCalls: this.updateIncomingCalls,
        callAccepted: this.callAccepted,
        callDone: this.callDone,
        volumeIndicators: this.volumeIndicators,
        updateQueues: this.updateQueues
      }
      const cli = new PhoneClient(user, handlers)
      this.client = await cli.init()
      this.setMyNumber(cli.tokenData.number)
      this.history = JSON.parse(localStorage.history || '[]')
    } catch (err) {
      console.log(err)
      this.setError(this.$t('MSG_FAIL_TO_FETCH_TOKEN'))
    }
  },
  created () {
    window.addEventListener('beforeunload', this.confirmDisconnect)
  },
  destroyed () {
    window.removeEventListener('beforeunload', this.confirmDisconnect)
  },
  methods: {
    ...mapMutations([
      'setMyNumber',
      'setDeviceState'
    ]),
    updateDeviceState (state) {
      this.setDeviceState(state)
    },
    confirmDisconnect (event) {
      console.log(this.$t('MSG_CONFIRM_DISCONNECT'))
      event.preventDefault()
      event.returnValue = this.$t('MSG_CONFIRM_DISCONNECT')
    },
    setLog (data) {
      console.log(data)
      this.logType = 'info is-light'
      this.log = data
    },
    setError (data) {
      console.log(data)
      this.logType = 'danger'
      this.log = data
    },
    setCounterNumber (_number) {
      if (_number) {
        this.counterNumber = phoneNumber(_number)
        this.addHistory(this.counterNumber)
      } else {
        this.counterNumber = null
      }
    },
    getCall (callSid) {
      return this.client.getCall(callSid)
    },
    addHistory (_number) {
      const set = new Set(this.history)
      set.add(phoneNumber(_number))
      this.history = Array.from(set).slice(0, 20)
      localStorage.history = JSON.stringify(this.history)
    },
    async readyToReceiveIncomingCall () {
      await this.reloadAllAudioDevices(true)
    },
    async reloadAllAudioDevices (stopAll = false) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      if (stopAll) stream.getTracks().forEach(track => track.stop())
      this.audio = this.client.updateAllAudioDevices()
    },
    updateIncomingCalls (calls) {
      console.log('updateIncomingCalls', calls)
      this.incomingCalls = calls
    },
    async incomingCallAccept (call) {
      try {
        await this.client.setInputDevice(this.audio.inputDevice || 'default')
        // patch to avoid the error https://github.com/twilio/twilio-voice.js/issues/67
        call.accept({ rtcConstraints: {} })
      } catch (err) {
        this.setError(err.message)
      }
    },
    incomingCallReject (call) {
      call.reject()
    },
    callAccepted (call) {
      console.log('accepted', call.formatedFrom || call.formatedTo)
      this.activeCall = call
      this.updateView()
    },
    callDone (call) {
      console.log('done', call.status())
      if (this.activeCall && (this.activeCall.sid === call.sid)) {
        this.activeCall = null
      }
      this.updateView()
    },
    updateView () {
      if (this.activeCall) {
        this.setCounterNumber(this.activeCall.formatedFrom || this.activeCall.formatedTo)
      } else {
        this.setCounterNumber(null)
      }
    },
    getFormatedPhoneNumber () {
      if (this.currentNumber.startsWith('+')) {
        return '+' + this.currentNumber.replace(/\D/g, '')
      } else if (this.currentNumber.match(/^[\d+\-() ]+$/)) {
        return '+' + this.countryCode + this.currentNumber.replace(/\D/g, '').replace(/^0/, '')
      } else {
        return this.currentNumber
      }
    },
    async startOutgoingCall () {
      await this.client.setInputDevice(this.audio.inputDevice || 'default')
      const params = { To: this.getFormatedPhoneNumber() }
      this.setCounterNumber(params.To)
      this.setLog(this.$t('MSG_ATTEMPTING_TO_CALL', [this.counterNumber]))
      this.activeCall = await this.client.makeOutgoingCall(params)
    },
    callHangup () {
      if (!this.onPhone) return
      this.setLog(this.$t('MSG_HANGING_UP', [this.counterNumber]))
      this.client.disconnect()
      console.log('callHangup')
    },
    volumeIndicators (inputVolume, outputVolume) {
      // console.log(inputVolume, outputVolume)
    },
    setSpeakerDevices (device) {
      this.client.setSpeakerDevices(device)
    },
    setRingtoneDevices (devices) {
      this.client.setRingtoneDevices(devices)
    },
    setInputDevice (devices) {
      this.client.setInputDevice(devices)
    },
    switchMute () {
      this.activeCall.mute(!this.isMuted)
    },
    async pause () {
      this.setLog(this.$t('MSG_ENQUEUE', [this.counterNumber]))
      await this.client.pause(this.activeCall)
      await this.getQueues(1)
    },
    async getQueues (delaySec = 0) {
      await this.client.getQueues(delaySec)
    },
    updateQueues (queues) {
      this.queues = queues
    },
    async popQueue (queueName) {
      const call = await this.client.getCallFromQueue(queueName)
      if (call) {
        const params = { Queue: queueName, CounterCallSid: call.callSid }
        this.setCounterNumber(params.Queue)
        this.setLog(this.$t('MSG_ATTEMPTING_TO_CALL', [this.counterNumber]))
        this.activeCall = await this.client.makeOutgoingCall(params)
      } else {
        this.setLog(this.$t('MSG_QUEUE_EMPTY', [phoneNumber(queueName)]))
        await this.getQueues()
      }
    },
    pushDTMF (key) {
      this.currentNumber += key
      if (this.activeCall) {
        this.activeCall.sendDigits(key)
        this.setLog(this.$t('MSG_PUSHED', [key]))
      }
    },
    formatPhoneNumber (_number) {
      return phoneNumber(_number)
    },
    async getCalllog () {
      this.calllogLoading = true
      try {
        this.calllogs = (await this.client.getCalllog()).logs || []
      } catch (err) {
        console.log(err)
      } finally {
        this.calllogLoading = false
      }
      // console.log(this.calllogs)
    }
  }
}
</script>

<style>
textarea:hover,  input:hover, textarea:active, input:active, textarea:focus,
button:focus, button:active, button:hover, label:focus, .btn:active,
.btn.active {
    outline:0px !important;
    -webkit-appearance:none;
}

.dropdown-menu li a {
  padding: 3px 10px;
}

.btn-circle {
  width: 49px;
  height: 49px;
  text-align: center;
  padding: 5px 0;
  font-size: 20px;
  line-height: 2.00;
  border-radius: 30px;
}

.on-phone {
  background-color: #ffd970;
  color: #000;
}

@media(max-width: 600px) {
  .counterNumber {
    font-size: 2rem !important;
    font-weight: 400;
    margin-bottom: 2rem;
    line-height: 1.125;
    color: white;
  }
  .banner {
    font-size: 1em;
    font-weight: 600;
    border-radius: 4px;
    padding: 0.2em;
    position: absolute;
    /* top: 25px; */
    right: 15px;
  }
}

@media(min-width: 600px) {
  .counterNumber {
    font-size: 3rem !important;
    font-weight: 600;
    margin-bottom: 3rem;
    line-height: 1.125;
    color: white;
  }
  .banner {
    font-size: 1.5em;
    font-weight: 600;
    border-radius: 4px;
    padding: 0.2em;
    position: absolute;
    top: 25px;
    right: 25px;
  }
}

.card-header {
  background-color: transparent;
  align-items: stretch;
  box-shadow: 0 1px 2px hsla(0, 0%, 4%, 0.1);
  display: flex;
}

.card-header-title {
  align-items: center;
  color: #363636;
  display: flex;
  flex-grow: 1;
  font-weight: 700;
  padding: 0.75rem;
  margin: 0;
}
.card-header-icon {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 0.75rem;
  justify-content: center;
}

.groupedCenterClass {
  display: flex;
  justify-content: center !important;
}

.card-header {
  background-color: transparent;
  align-items: stretch;
  box-shadow: 0 1px 2px hsla(0, 0%, 4%, 0.1);
  display: flex;
}

.card-header-title {
  align-items: center;
  color: #363636;
  display: flex;
  flex-grow: 1;
  font-weight: 700;
  padding: 0.75rem;
  margin: 0;
}
.card-header-icon {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 0.75rem;
  justify-content: center;
}

.groupedCenterClass {
  display: flex;
  justify-content: center !important;
}

.modalFront {
  z-index: 1
}

.inputButton {
  display: flex;
  justify-content: space-evenly !important;
}

#dialer {
  font-family: Helvetica, sans-serif;
  padding: 0;
}
</style>
