<template>
  <div class="home">
    <section class="hero is-primary" v-if="auth.route !== 'authenticated'">
      <div class="hero-body">
        <div class="container center">
          <h1 class="title">
            123ROBO
          </h1>
          <h2 class="subtitle">
            {{ $t('AppName') }}
          </h2>
        </div>
      </div>
    </section>
    <authenticator :login-mechanisms="['username']" :hide-sign-up="true">
      <template v-slot="{user, signOut}">
      <Menu :user="user" :signOut="signOut" />
      <div class="columns section">
        <div class="column"></div>
        <div class="column is-half">
          <dialer-app />
        </div>
        <div class="column"></div>
      </div>
      </template>
    </authenticator>
  </div>
</template>

<script>
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-vue'
import '@aws-amplify/ui-vue/styles.css'
import Menu from '@/components/Menu.vue'
import DialerApp from '@/components/DialerApp.vue'

export default {
  name: 'App',
  components: {
    Menu,
    DialerApp,
    Authenticator
  },
  setup () {
    const auth = useAuthenticator()
    return {
      auth
    }
  },
  beforeRouteLeave (to, from, next) {
    const answer = window.confirm(this.$t('MSG_CONFIRM_DISCONNECT'))
    if (answer) {
      next()
    } else {
      next(false)
    }
  }
}
</script>

<style lang="css">
:root {
  --amplify-shadows-medium: 0px 2px 6px hsla(210, 50%, 10%, .0);
}

.amplify-button[data-variation='primary'] {
  background-color: #00d1b2;
}

</style>
